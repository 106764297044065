<template>
  <div class="moreIndexView content-index">
    <div class="title"><span class="icon"></span>衣物</div>
    <div class="content-box">
      <div
        class="content-item"
        v-for="(item, index) in commonList"
        :key="index"
        @click="handleRouter(item.path)"
      >
        <img :src="item.img" class="img" alt="" />
        <div class="name">{{ item.featureName }}</div>
      </div>
    </div>
    <div class="title"><span class="icon"></span>订单</div>
    <div class="content-box">
      <div
        class="content-item"
        v-for="(item, index) in orderList"
        :key="index"
        @click="handleRouter(item.path)"
      >
        <img :src="item.img" class="img" alt="" />
        <div class="name">{{ item.featureName }}</div>
      </div>
    </div>
    <div class="title"><span class="icon"></span>用户</div>
    <div class="content-box">
      <div
        class="content-item"
        v-for="(item, index) in userList"
        :key="index"
        @click="handleRouter(item.path)"
      >
        <img :src="item.img" class="img" alt="" />
        <div class="name">{{ item.featureName }}</div>
      </div>
    </div>
    <div class="title"><span class="icon"></span>财务</div>
    <div class="content-box">
      <div
        class="content-item"
        v-for="(item, index) in financeList"
        :key="index"
        @click="handleRouter(item.path)"
      >
        <img :src="item.img" class="img" alt="" />
        <div class="name">{{ item.featureName }}</div>
      </div>
    </div>
    <div class="title"><span class="icon"></span>配置</div>
    <div class="content-box">
      <div
        class="content-item"
        v-for="(item, index) in configList"
        :key="index"
        @click="handleRouter(item.path)"
      >
        <img :src="item.img" class="img" alt="" />
        <div class="name">{{ item.featureName }}</div>
      </div>
    </div>
    <div class="title"><span class="icon"></span>物流</div>
    <div class="content-box">
      <div
        class="content-item"
        v-for="(item, index) in logisticsList"
        :key="index"
        @click="handleRouter(item.path)"
      >
        <img :src="item.img" class="img" alt="" />
        <div class="name">{{ item.featureName }}</div>
      </div>
    </div>
    <div class="title"><span class="icon"></span>商品</div>
    <div class="content-box">
      <div
        class="content-item"
        v-for="(item, index) in goodsList"
        :key="index"
        @click="handleRouter(item.path)"
      >
        <img :src="item.img" class="img" alt="" />
        <div class="name">{{ item.featureName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import clothes from "_img/clothes.png";
import caogaoxiang from "_img/caogaoxiang.png";
import takeexpress from "_img/take-express_fill.png";
import yijia from "_img/yijia.png";
import jianbianffe from "_img/wuliu-jianbianffe_ffdf_px.png";
import order from "_img/order.png";
import rcharge from "_img/rcharge.jpg";
import shop from "_img/shop.png";
import fan from "_img/fan.png";
import chao from "_img/chao.png";
import reminder from "_img/reminder.png";
import flaw from "_img/11.png";
import applyCardList from "_img/applyCardList.png";
import Complaint from "_img/complaint.png";
import WashDetails from "_img/washDetails.png";
import Inventory from "_img/inventory.png";
import tuixi from "_img/tuixi.png";
import tuikuan from "_img/tuikuan.png";
import zhangdan from "_img/zhangdan.png";
import reserve from "_img/reserve.png";
import kapeizhi from "_img/kapeizhi.png";
import user from "_img/user.png";
import card from "_img/card.jpg";
import shouzhi from "_img/shouzhi.png";
import shouhou from "_img/shouhou.png";

export default {
  name: "index",
  data() {
    return {
      commonList: [
        {
          featureName: "收衣",
          img: clothes,
          path: "query",
        },
        {
          featureName: "送洗",
          img: jianbianffe,
          path: "sendWash",
        },
        {
          featureName: "入店",
          img: shop,
          path: "enterShop",
        },
        {
          featureName: "上挂",
          img: yijia,
          path: "shelvesIndex",
        },
        {
          featureName: "取衣",
          img: takeexpress,
          path: "takeClothesIndex",
        },
        {
          featureName: "返洗",
          img: fan,
          path: "rewashing",
        },
        {
          featureName: "草稿箱",
          img: caogaoxiang,
          path: "draftBox",
        },
        {
          featureName: "催单",
          img: reminder,
          path: "reminders",
        },
        {
          featureName: "瑕疵确认",
          img: flaw,
          path: "flaw",
        },
        {
          featureName: "衣物退洗",
          img: tuixi,
          path: "backWashing",
        },
        {
          featureName: "衣物盘点",
          img: Inventory,
          path: "clothinginventory",
        },
        {
          featureName: "衣物超时",
          img: chao,
          path: "timeout",
        },
          {
              featureName: "快速盘点",
              img: Inventory,
              path: "fastCheck",
          },
      ],
      orderList: [
        {
          featureName: "订单",
          img: order,
          path: "order",
        },
        {
          featureName: "预约",
          img: reserve,
          path: "reserve",
        },
        {
          featureName: "售后订单",
          img: shouhou,
          path: "afterSale",
        },
        {
          featureName: "洗涤明细",
          img: WashDetails,
          path: "washDetails",
        },
        {
          featureName: "退款订单",
          img: order,
          path: "refundOrder",
        },
      ],
      userList: [
        {
          featureName: "用户",
          img: user,
          path: "user",
        },
        {
          featureName: "办卡",
          img: card,
          path: "applyCard",
        },
        {
          featureName: "充值",
          img: rcharge,
          path: "charger",
        },
        {
          featureName: "退款退赔",
          img: tuikuan,
          path: "refund",
        },
        {
          featureName: "办卡列表",
          img: applyCardList,
          path: "applyCardList",
        },
        {
          featureName: "投诉建议",
          img: Complaint,
          path: "complaint",
        },
        {
          featureName: "验券中心",
          img: Complaint,
          path: "Meituan",
        },
        {
          featureName: "抖音核销明细",
          img: Complaint,
          path: "tiktokCertificate",
        },
      ],
      financeList: [
        {
          featureName: "收支",
          img: shouzhi,
          path: "incomeExpenses",
        },
        {
          featureName: "财务对账",
          img: zhangdan,
          path: "accounting",
        },
      ],
      configList: [
        {
          featureName: "卡配置",
          img: kapeizhi,
          path: "cardSet",
        },
      ],
      logisticsList: [
        {
          featureName: "袋子管理",
          img: takeexpress,
          path: "bagManagement",
        },
      ],
      goodsList: [
        {
          featureName: "商品零售",
          img: zhangdan,
          path: "retailing",
        },
        {
          featureName: "商品订单",
          img: zhangdan,
          path: "commodityOrder",
        },
        {
          featureName: "采购管理",
          img: zhangdan,
          path: "purchase",
        },
        {
          featureName: "商品入库",
          img: zhangdan,
          path: "stockManagement",
        },
        {
          featureName: "商品出库",
          img: zhangdan,
          path: "outManagement",
        },
        {
          featureName: "库存管理",
          img: zhangdan,
          path: "stockInquiry",
        },
      ],
    };
  },
  methods: {
    handleRouter(path) {
      this.$router.push({ name: path });
    },
  },
};
</script>

<style scoped lang="scss">
.moreIndexView {
  position: relative;
  font-family: PingFangSC-Regular;
  // padding: 95px 0 0 0;
  overflow: auto;
  padding: 0 0 25px;

  .title {
    display: flex;
    color: rgba(16, 16, 16, 100);
    font-size: 16px;
    align-items: center;
    padding: 25px 25px 0;

    .icon {
      height: 16px;
      width: 4px;
      background-color: #3370ff;
      margin-right: 3px;
      border-radius: 5px;
    }
  }

  .content-box {
    display: flex;
    flex-wrap: wrap;
    text-align: center;

    .content-item {
      padding-top: 20px;
      width: 14%;
      color: rgba(16, 16, 16, 100);
      font-size: 16px;
      cursor: pointer;

      .img {
        width: 110px;
        height: 110px;
      }

      .name {
        text-align: center;
        padding-top: 10px;
      }
    }
  }
}

div {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
</style>
